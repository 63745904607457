// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Dashboard',
      path: '/home',
      icon: 'tabler:smart-home'
    },
    {
      title: 'Forms',
      path: '/forms',
      icon: 'tabler:file-text'
    },
    {
      title: 'VIPs',
      path: '/vips',
      icon: 'tabler:hierarchy-3'

      // disabled: true
    },
    {
      title: 'Products',
      path: '/products',
      icon: 'tabler:building-store'

      // disabled: true
    }
  ]
}

export default navigation
