// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'
import { unit_data } from 'src/interfaces'

interface IInitialState {
  isLoading: boolean
  error: string | undefined | null
  all_results: unit_data[]
}

const getToken = () => {
  if (typeof window !== 'undefined') {
    return `Bearer ${JSON.parse(window.localStorage.getItem('userData')!).session}`
  }
}

export const getAllUnit = createAsyncThunk('unit/fetchAll', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.request({
      method: 'GET',
      url: getApiUrl(`unit/`),
      headers: {
        Authorization: getToken()
      }
    })

    return response.data
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

const initialState: IInitialState = {
  isLoading: false,
  error: undefined,
  all_results: [
    {
      _id: 'string',
      code: 'string',
      unit: 'string',
      description: 'string',
      createdAt: 'string',
      updatedAt: 'string'
    }
  ]
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    removeErrors: state => {
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllUnit.pending, state => {
        state.isLoading = true
      })
      .addCase(getAllUnit.fulfilled, (state, action) => {
        state.isLoading = false
        state.all_results = action.payload
      })
      .addCase(getAllUnit.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
  }
})

export const { removeErrors } = formSlice.actions
export default formSlice.reducer
