// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import products from 'src/store/products'
import principals from 'src/store/principals'
import forms from 'src/store/forms'
import vips from 'src/store/vip'
import aws from 'src/store/aws'
import unit from 'src/store/unit'

export const store = configureStore({
  reducer: {
    products,
    principals,
    forms,
    vips,
    aws,
    unit
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
