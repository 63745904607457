// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'

// ** Types
import { IPrincipal } from 'src/views/pages/product-nav/principals/PrincipalList'

const getToken = () => {
  if (typeof window !== 'undefined') {
    return `Bearer ${JSON.parse(window.localStorage.getItem('userData')!)?.session}`
  }
}

interface IPrincipalRequestParams {
  limit: number
  page: number
  order: 'ASCENDING' | 'DESCENDING'
  orderBy?: string
  searchText?: string
  sort?: string
  filterField?: string
  filterValue?: string
  filterRegex?: boolean
}

interface IPrincipalUpdate {
  id: string
  formData: FormData
}

interface IBaseState<T> {
  totalResults: number
  totalPages: number
  isLoading: boolean
  results: T[]
  error: string | undefined | null
  principal: T | null
}

type IInitialState = IPrincipalRequestParams & IBaseState<IPrincipal>

// export const getPrincipals = createAsyncThunk(
//   'principal/fetchPrincipals',
//   async (params: IPrincipalRequestParams, { rejectWithValue }) => {
//     try {
//       const paginatedRes = await axios.get(getApiUrl('principal'), {
//         params: {
//           limit: params.limit,
//           page: params.page,
//           sortBy: `${params.orderBy}:${params.order}`,
//           searchText: params.searchText
//         },
//         headers: {
//           Authorization: getToken()
//         }
//       })

//       console.log(paginatedRes)

//       const { totalResults, totalPages } = paginatedRes.data
//       const results = paginatedRes.data.results.map((item: IPrincipal) => ({
//         id: item._id,
//         ...item
//       }))

//       return {
//         results,
//         totalResults,
//         totalPages
//       }
//     } catch (error) {
//       return rejectWithValue(getError(error))
//     }
//   }
// )

export const getPrincipals = createAsyncThunk(
  'principal/fetchPrincipals',
  async (params: IPrincipalRequestParams, { rejectWithValue }) => {
    try {
      const paginatedRes = await axios.get(getApiUrl('principal'), {
        params: {
          limit: params.limit,
          page: params.page,
          order: params.order,
          filterField: params.filterField,
          filterValue: params.filterValue,
          filterRegex: params.filterRegex
        },
        headers: {
          Authorization: getToken()
        }
      })

      return {
        results: paginatedRes.data.data,
        totalResults: paginatedRes.data.page_info.total_count,
        totalPages: paginatedRes.data.page_info.pages
      }
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const getPrincipal = createAsyncThunk('principal/fetchPrincipal', async (id: string, { rejectWithValue }) => {
  try {
    const principal = await axios.get(getApiUrl(`principal/${id}`), {
      headers: {
        Authorization: getToken()
      }
    })

    return principal.data
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const addPrincipal = createAsyncThunk('principal/add', async (formData: FormData, { rejectWithValue }) => {
  try {
    await axios.request({
      method: 'POST',
      url: getApiUrl('principal'),
      maxBodyLength: Infinity,
      headers: {
        Authorization: getToken()
      },
      data: formData
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const updatePrincipal = createAsyncThunk(
  'principal/update',
  async (params: IPrincipalUpdate, { rejectWithValue }) => {
    try {
      await axios.request({
        method: 'PATCH',
        maxBodyLength: Infinity,
        url: getApiUrl(`principal/${params.id}`),
        headers: {
          Authorization: getToken()
        },
        data: params.formData
      })
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const deletePrincipal = createAsyncThunk('principal/delete', async (id: string, { rejectWithValue }) => {
  try {
    await axios.delete(getApiUrl(`principal/${id}`), {
      headers: {
        Authorization: getToken()
      }
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

const initialState: IInitialState = {
  results: [],
  totalResults: 0,
  totalPages: 0,
  isLoading: false,
  error: undefined,
  limit: 5,
  page: 1,
  orderBy: 'createdAt',
  order: 'ASCENDING',
  searchText: '',
  principal: null
}

export const productSlice = createSlice({
  name: 'principal',
  initialState,
  reducers: {
    removeErrors: state => {
      state.error = null
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASCENDING' | 'DESCENDING'>) => {
      state.order = action.payload
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPrincipals.pending, state => {
        state.isLoading = true
      })
      .addCase(getPrincipals.fulfilled, (state, action) => {
        state.results = action.payload?.results
        state.totalPages = action.payload?.totalPages
        state.totalResults = action.payload?.totalResults
        state.isLoading = false
      })
      .addCase(getPrincipals.rejected, (state, action) => {
        state.isLoading = false
        state.results = []
        state.totalPages = 0
        state.totalResults = 0
        state.error = action.error.message
      })
    builder
      .addCase(getPrincipal.pending, state => {
        state.isLoading = true
      })
      .addCase(getPrincipal.fulfilled, (state, action) => {
        state.principal = action.payload
        state.isLoading = false
      })
      .addCase(getPrincipal.rejected, state => {
        state.principal = null
        state.isLoading = false
      })
    builder
      .addCase(addPrincipal.pending, state => {
        state.isLoading = true
      })
      .addCase(addPrincipal.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(addPrincipal.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(updatePrincipal.pending, state => {
        state.isLoading = true
      })
      .addCase(updatePrincipal.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(updatePrincipal.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })
  }
})

export const { removeErrors, setLimit, setPage, setOrder, setOrderBy, setSearchText } = productSlice.actions
export default productSlice.reducer
