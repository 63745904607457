// ** Type import
import { HorizontalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): HorizontalNavItemsType => [
  {
    title: 'Dashboard',
    path: '/home',
    icon: 'tabler:smart-home'
  },
  {
    title: 'Forms',
    path: '/forms',
    icon: 'tabler:file-text'
  },
  {
    title: 'Products',
    path: '/products',
    icon: 'tabler:building-store'
  }
]

export default navigation
