import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { getExhibitorDetails } from 'src/store/forms';

const useGetMyDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const exhibitor = useSelector((state: RootState) => state.forms);

  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        await dispatch(getExhibitorDetails());
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchForms();
  }, [dispatch]);

  return { exhibitor, loading };
}

export default useGetMyDetails