// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'

// ** Types
import { IProduct } from 'src/views/pages/product-nav/products/ProductList'
import { IPageReqParams, IPageResParams } from 'src/interfaces'

const getToken = () => {
  if (typeof window !== 'undefined') {
    return `Bearer ${JSON.parse(window.localStorage.getItem('userData')!)?.session}`
  }
}

interface IProductUpdate {
  id: string
  formData: FormData
}

type IInitialState = IPageReqParams & IPageResParams<IProduct>

export const getProducts = createAsyncThunk(
  'products/fetchProducts',
  async (params: IPageReqParams, { rejectWithValue }) => {
    try {
      const paginatedRes = await axios.get(getApiUrl('product'), {
        params: {
          limit: params.limit,
          page: params.page,
          order: params.order,
          filterField: params.filterField,
          filterValue: params.filterValue,
          filterRegex: params.filterRegex,
          principal_id: params.principal_id
        },
        headers: {
          Authorization: getToken()
        }
      })

      return {
        results: paginatedRes.data.data,
        totalResults: paginatedRes.data.page_info.total_count,
        totalPages: paginatedRes.data.page_info.pages
      }
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const addProduct = createAsyncThunk('products/add', async (formData: FormData, { rejectWithValue }) => {
  console.log(formData)
  try {
    await axios.request({
      method: 'POST',
      url: getApiUrl('product'),
      maxBodyLength: Infinity,
      headers: {
        Authorization: getToken()
      },
      data: formData
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const updateProduct = createAsyncThunk(
  'products/update',
  async (params: IProductUpdate, { rejectWithValue }) => {
    try {
      await axios.request({
        method: 'PATCH',
        maxBodyLength: Infinity,
        url: getApiUrl(`product/${params.id}`),
        headers: {
          Authorization: getToken()
        },
        data: params.formData
      })
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const deleteProduct = createAsyncThunk('product/delete', async (id: string, { rejectWithValue }) => {
  try {
    await axios.delete(getApiUrl(`product/${id}`), {
      headers: {
        Authorization: getToken()
      }
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

const initialState: IInitialState = {
  results: [],
  totalResults: 0,
  totalPages: 0,
  isLoading: false,
  error: undefined,
  limit: 5,
  page: 1,
  sort: 'createdAt',
  order: 'ASCENDING',
  searchText: '',
  selectedResult: null
}

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    removeErrors: state => {
      state.error = null
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASCENDING' | 'DESCENDING'>) => {
      state.order = action.payload
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProducts.pending, state => {
        state.isLoading = true
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.results = action.payload?.results
        state.totalPages = action.payload?.totalPages
        state.totalResults = action.payload?.totalResults
        state.isLoading = false
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false
        state.results = []
        state.totalPages = 0
        state.totalResults = 0
        state.error = action.error.message
      })
    builder
      .addCase(addProduct.pending, state => {
        state.isLoading = true
      })
      .addCase(addProduct.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(updateProduct.pending, state => {
        state.isLoading = true
      })
      .addCase(updateProduct.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })
  }
})

export const { removeErrors, setLimit, setPage, setOrder, setOrderBy, setSearchText } = productSlice.actions
export default productSlice.reducer
