// ** MUI Imports
import { Typography, styled } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import Paper from '@mui/material/Paper'


// ** Icon Imports
import Icon from 'src/@core/components/icon'
import { useSettings } from 'src/@core/hooks/useSettings'


// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import useGetMyDetails from 'src/@core/hooks/useGetMyDetails'

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

type PaperType = NonNullable<Parameters<typeof Paper>[0]>

const CustomPaper = styled(Paper)<PaperType>(({ theme }) => ({
  padding: '0 1rem ',
  overflowY: 'auto',
  backgroundColor: `${useSettings().settings.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
}))

const AppBarContent = (props: Props) => {
  const { exhibitor } = useGetMyDetails();

  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <Icon fontSize='1.5rem' icon='tabler:menu-2' />
          </IconButton>
        ) : null}

        <ModeToggler settings={settings} saveSettings={saveSettings} />
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomPaper>
          <Typography variant='subtitle1'>Exhibitor ID: {exhibitor?.all_results.custom_id}</Typography>
        </CustomPaper>
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
