import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'

// ** Token
import { getAccessToken } from 'src/@core/utils/get-token'

interface IInitialState {
  uploadUrl: string
  isLoading: boolean
  error: string | undefined | null
}

interface IGetSignedUrlParams {
  fileType: string
  fileSize: number
  fileName: string
}
export const requestUploadUrl = createAsyncThunk(
  'aws/requestUploadUrl',
  async (params: IGetSignedUrlParams, { rejectWithValue }) => {
    try {
      const res = await axios.request({
        method: 'POST',
        url: getApiUrl('upload/presigned-url'),
        headers: {
          Authorization: getAccessToken()
        },
        data: params
      })

      return res.data
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const getSignedUrl = createAsyncThunk(
  'aws/getSignedUrl',
  async (params: { s3ObjectKey: string }, { rejectWithValue }) => {
    try {
      const res: any = await axios.request({
        method: 'POST',
        url: getApiUrl(`upload/signed-url`),
        headers: {
          Authorization: getAccessToken()
        },
        data: params
      })

      return res
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const uploadFileToS3 = createAsyncThunk(
  'aws/uploadFileToS3',
  async (params: { file: File; url: string }, { rejectWithValue }) => {
    try {
      const res = await fetch(params.url, {
        method: 'PUT',
        headers: {
          'Content-Type': params.file.type
        },
        body: params.file
      })

      return res.status
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const initialState: IInitialState = {
  uploadUrl: '',
  isLoading: false,
  error: null
}

export const awsSlice = createSlice({
  name: 'aws',
  initialState,
  reducers: {
    setUploadUrl: (state, action: PayloadAction<string>) => {
      state.uploadUrl = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(requestUploadUrl.pending, state => {
        state.isLoading = true
      })
      .addCase(requestUploadUrl.fulfilled, (state, action) => {
        state.uploadUrl = action.payload
        state.isLoading = false
      })
      .addCase(requestUploadUrl.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })
  }
})

export const { setUploadUrl } = awsSlice.actions
export default awsSlice.reducer
