// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'
import { form_data } from 'src/interfaces'

interface IInitialState {
  isLoading: boolean
  error: string | undefined | null
  single_result: object
  all_results: {
    custom_id: string
    forms: form_data[]
    exhibitor_booking: {
      company_name: string
    }
    complimentary_code: {
      code: string
      company_name: string
      limits: number | string
    }
    user: {
      email: string
      exhibitor: string
      name: string
      alpha_code: string
    }
  }
  created_result: {
    data: any
  }
}

const getToken = () => {
  if (typeof window !== 'undefined') {
    return `Bearer ${JSON.parse(window.localStorage.getItem('userData')!).session}`
  }
}

export const createForm = createAsyncThunk('form/create', async (formData: any, { rejectWithValue }) => {
  // console.log(formData)
  try {
    const response = await axios.request({
      method: 'POST',
      url: getApiUrl('form'),
      headers: {
        Authorization: getToken()
      },
      data:
        formData.name === 'Company Name Signage Form'
          ? formData.data
          : {
              data: formData.data,
              name: formData.name,
              is_signed: formData.is_signed
            }
    })

    return {
      results: response.data
    }
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const updateForm = createAsyncThunk('form/updateform', async (formData: any, { rejectWithValue }) => {
  // console.log(formData.data)
  try {
    const response = await axios.request({
      method: 'PATCH',
      url: getApiUrl(`form/${formData.id}`),
      headers: {
        Authorization: getToken()
      },
      data:
        formData.name === 'Company Name Signage Form'
          ? formData.data
          : {
              data: formData.data,
              name: formData.name,
              is_signed: formData.is_signed
            }
    })

    return {
      results: response.data
    }
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const getExhibitorDetails = createAsyncThunk('form/fetchAll', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.request({
      method: 'GET',
      url: getApiUrl(`exhibitor/mine`),
      headers: {
        Authorization: getToken()
      }
    })

    return response.data
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

// export const fetchComplimentaryCodes = createAsyncThunk(
//   'form/fetchComplimentaryCodes',
//   async (company_name: string, { rejectWithValue }) => {
//     try {
//       const response = await axios.request({
//         method: 'GET',
//         url: getApiUrl(`complimentary-code/${company_name}`),
//         headers: {
//           Authorization: getToken()
//         }
//       })

//       return {
//         results: response.data
//       }
//     } catch (error) {
//       return rejectWithValue(getError(error))
//     }
//   }
// )

export const getSingleForm = createAsyncThunk('form/formName', async (id: string, { rejectWithValue }) => {
  try {
    const results = await axios.request({
      method: 'GET',
      url: getApiUrl(`form/${id}`),
      headers: {
        Authorization: getToken()
      }
    })

    return {
      results
    }
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

const initialState: IInitialState = {
  isLoading: false,
  error: undefined,
  single_result: {},
  created_result: {
    data: {}
  },
  all_results: {
    custom_id: '',
    forms: [
      {
        _id: '',
        name: '',
        data: '',
        image_url: '',
        files: [''],
        exhibitor_id: '',
        is_signed: false,
        createdAt: '',
        updatedAt: ''
      }
    ],
    exhibitor_booking: {
      company_name: ''
    },
    complimentary_code: {
      code: '',
      company_name: '',
      limits: ''
    },
    user: {
      email: '',
      exhibitor: '',
      name: '',
      alpha_code: ''
    }
  }
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    removeErrors: state => {
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createForm.pending, state => {
        state.isLoading = true
      })
      .addCase(createForm.fulfilled, (state, action) => {
        state.isLoading = false
        state.created_result = action.payload?.results
      })
      .addCase(createForm.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(getSingleForm.pending, state => {
        state.isLoading = true
      })
      .addCase(getSingleForm.fulfilled, (state, action) => {
        state.isLoading = false
        state.single_result = action.payload?.results
      })
      .addCase(getSingleForm.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(getExhibitorDetails.pending, state => {
        state.isLoading = true
      })
      .addCase(getExhibitorDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.all_results = action.payload
      })
      .addCase(getExhibitorDetails.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(updateForm.pending, state => {
        state.isLoading = true
      })
      .addCase(updateForm.fulfilled, (state, action) => {
        state.isLoading = false
        state.created_result = action.payload?.results
      })
      .addCase(updateForm.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })

    // builder
    //   .addCase(fetchComplimentaryCodes.pending, state => {
    //     state.isLoading = true
    //   })
    //   .addCase(fetchComplimentaryCodes.fulfilled, (state, action) => {
    //     state.isLoading = false
    //     state.complimentary_pass = action.payload.results
    //   })
    //   .addCase(fetchComplimentaryCodes.rejected, (state, action) => {
    //     state.error = action.error.message
    //     state.isLoading = false
    //   })
  }
})

export const { removeErrors } = formSlice.actions
export default formSlice.reducer
