import { AxiosError } from 'axios'

export const getError = (error: unknown) => {
  let errorMessage = 'Something went wrong'

  if (error instanceof Error) {
    errorMessage = error.message
  }

  if (error instanceof AxiosError) {
    if (error.response?.data.statusCode === 403) {
      window.localStorage.removeItem('userData')
    }
    errorMessage = error.response?.data?.message ?? error.message
  }

  return errorMessage
}
