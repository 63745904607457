import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getError } from 'src/@core/utils/get-errors'
import { getApiUrl } from 'src/@core/utils/url'

// ** Types
// import { IProduct } from 'src/views/pages/product-nav/products/ProductList'

// ** Token
import { getAccessToken } from 'src/@core/utils/get-token'
import { IPageReqParams, IPageResParams, IVip } from 'src/interfaces'

type IInitialState = IPageReqParams & IPageResParams<IVip>

// export const getVips = createAsyncThunk('vip/fetchVips', async (params: IPageReqParams, { rejectWithValue }) => {
//   try {
//     const paginatedRes = await axios.get(getApiUrl('vip'), {
//       params: {
//         limit: params.limit,
//         page: params.page,
//         sort: `${params.sort}`
//       },
//       headers: {
//         Authorization: getAccessToken()
//       }
//     })
//     const { totalResults, totalPages } = paginatedRes.data
//     const results = paginatedRes.data.results.map((item: IProduct) => ({
//       id: item._id,
//       ...item
//     }))

//     console.log(paginatedRes)

//     return {
//       results,
//       totalResults,
//       totalPages
//     }

//   } catch (error) {
//     return rejectWithValue(getError(error))
//   }
// })

export const getVips = createAsyncThunk('vip/fetchVips', async (params: IPageReqParams, { rejectWithValue }) => {
  try {
    const paginatedRes = await axios.request({
      method: 'GET',
      url: getApiUrl('vip'),
      params: {
        limit: params.limit,
        page: params.page,
        order: params.order,
        filterField: params.filterField,
        filterValue: params.filterValue,
        filterRegex: params.filterRegex
      },
      headers: {
        Authorization: getAccessToken()
      }
    })

    return {
      results: paginatedRes.data.data,
      totalResults: paginatedRes.data.page_info.total_count,
      totalPages: paginatedRes.data.page_info.pages
    }
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const getVip = createAsyncThunk('vip/fetchVip', async (id: string, { rejectWithValue }) => {
  try {
    const vip = await axios.get(getApiUrl(`vip/${id}`), {
      headers: {
        Authorization: getAccessToken()
      }
    })

    return vip.data
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const addVip = createAsyncThunk('vip/add', async (data: Omit<IVip, '_id'>, { rejectWithValue }) => {
  try {
    await axios.request({
      method: 'POST',
      url: getApiUrl('vip'),
      headers: {
        Authorization: getAccessToken()
      },
      data
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const addBulkVip = createAsyncThunk('vip/addBulk', async (data: FormData, { rejectWithValue }) => {
  try {
    await axios.request({
      method: 'POST',
      url: getApiUrl('vip/bulk'),
      headers: {
        Authorization: getAccessToken()
      },
      data
    })
  } catch (error) {
    return rejectWithValue(getError(error))
  }
})

export const updateVip = createAsyncThunk(
  'vip/update',
  async ({ data, id }: { data: IVip; id: string }, { rejectWithValue }) => {
    try {
      await axios.request({
        method: 'PUT',
        url: getApiUrl(`vip/${id}`),
        headers: {
          Authorization: getAccessToken()
        },
        data
      })
    } catch (error) {
      return rejectWithValue(getError(error))
    }
  }
)

export const initialState: IInitialState = {
  results: [],
  totalResults: 0,
  totalPages: 0,
  isLoading: false,
  error: null,
  limit: 5,
  page: 1,
  sort: 'createdAt',
  order: 'DESCENDING',
  searchText: '',
  selectedResult: null
}

export const vipSlice = createSlice({
  name: 'vips',
  initialState,
  reducers: {
    removeErrors: state => {
      state.error = null
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASCENDING' | 'DESCENDING'>) => {
      state.order = action.payload
    },
    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }

    // setIndustry: (state, action: PayloadAction<string>) => {
    //   state.industrySearch = action.payload
    // }
  },
  extraReducers: builder => {
    builder
      .addCase(getVips.pending, state => {
        state.isLoading = true
      })
      .addCase(getVips.fulfilled, (state, action) => {
        state.results = action.payload?.results
        state.totalPages = action.payload?.totalPages
        state.totalResults = action.payload?.totalResults
        state.isLoading = false
      })
      .addCase(getVips.rejected, (state, action) => {
        state.isLoading = false
        state.results = []
        state.totalPages = 0
        state.totalResults = 0
        state.error = action.error.message
      })
    builder
      .addCase(getVip.pending, state => {
        state.isLoading = true
      })
      .addCase(getVip.fulfilled, (state, action) => {
        state.selectedResult = action.payload
        state.isLoading = false
      })
      .addCase(getVip.rejected, state => {
        state.selectedResult = null
        state.isLoading = false
      })
    builder
      .addCase(addVip.pending, state => {
        state.isLoading = true
      })
      .addCase(addVip.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(addVip.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })
    builder
      .addCase(addBulkVip.pending, state => {
        state.isLoading = true
      })
      .addCase(addBulkVip.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(addBulkVip.rejected, (state, action) => {
        state.error = action.error.message
        state.isLoading = false
      })

    // builder
    //   .addCase(updateVip.pending, state => {
    //     state.isLoading = true
    //   })
    //   .addCase(updateVip.fulfilled, state => {
    //     state.isLoading = false
    //   })
    //   .addCase(updateVip.rejected, (state, action) => {
    //     state.isLoading = false
    //     state.error = action.error.message
    //   })
  }
})

export const { removeErrors, setLimit, setPage, setOrder, setSort, setSearchText } = vipSlice.actions
export default vipSlice.reducer
