// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import axios from 'axios'

// ** Types
import { AuthValuesType, LoginParams, ErrCallbackType, OTPParams, SuccessCallbackType } from './types'

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  credentials: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  OTP: () => Promise.resolve(),
  resendOtp: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  changeCurrentPassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
}

// const NEW_PASSWORD_REQUIRED = 'REQUIRE_NEW_PASSWORD'

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [credentials, setCredentials] = useState<any | null>(defaultProvider.credentials)
  const [user, setUser] = useState<any | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      if (window.localStorage.getItem('userData')) {
        setLoading(true)
        const userData = JSON.parse(window.localStorage.getItem('userData')!)

        // //jsonwebtoken should be set here
        // const decodedToken: any = jsonwebtoken.decode(userData.session)
        // const expirationDate = new Date(decodedToken?.exp * 1000)

        // if (expirationDate < new Date()) {
        //   const returnUrl = router.query.returnUrl

        //   // remove all user credentials upon expiration of token when the user refreshes or changes the page
        //   setUser(null)

        //   window.localStorage.removeItem('userData')
        //   const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/login'

        //   router.replace(redirectURL as string)
        // } else {
        setUser({
          token: userData.session,
          email: userData.email,
          role: 'admin',
          id: userData.id
        })

        // }

        setLoading(false)
      }
      setLoading(false)
    }
    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Configuration of login authentication
  // const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
  //   axios
  //     .post(process.env.NEXT_PUBLIC_KEY + '/auth/login', {
  //       ...params
  //     })
  //     .then(async ({ data }: any) => {
  //       if (data.errors) {
  //         errorCallback && errorCallback(data.data.errors[0].message!)
  //       } else {
  //         setCredentials({ ...params })

  //         const returnUrl = router.query.returnUrl

  //         if (data.challenge === 'OTP') {
  //           const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/two-step'
  //           router.replace(redirectURL as string)

  //         } else {
  //           const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
  //           setUser({
  //             email: params.email,
  //             role: 'admin',
  //             token: data.token,
  //             id: data.id
  //           })
  //           window.localStorage.setItem(
  //             'userData',
  //             JSON.stringify({
  //               email: params.email,
  //               session: data.token,
  //               role: 'admin'
  //             })
  //           )

  //           router.replace(redirectURL as string)
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       if (errorCallback) errorCallback(err)
  //     })
  // }

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/login', {
        ...params
      })
      .then(async ({ data }: any) => {
        if (data.errors) {
          errorCallback && errorCallback(data.data.errors[0].message!)
        } else {
          setCredentials({ ...params })

          const returnUrl = router.query.returnUrl

          // if (data.challenge === 'OTP') {
          //   const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/two-step'
          //   router.replace(redirectURL as string)

          // } else {
          const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
          setUser({
            email: params.email,
            role: 'admin',
            token: data.token,
            id: data.id
          })
          window.localStorage.setItem(
            'userData',
            JSON.stringify({
              email: params.email,
              session: data.token,
              role: 'admin'
            })
          )

          router.replace(redirectURL as string)

          // }
        }
      })
      .catch(err => {
        console.error(err)
        if (errorCallback) errorCallback(err)
      })
  }

  // // configuration of OTP
  // const handleOTP = ({ otp }: OTPParams, errorCallback?: ErrCallbackType) => {
  //   axios
  //     .post(process.env.NEXT_PUBLIC_KEY + '/auth/login', {
  //       ...credentials,
  //       otp
  //     })
  //     .then(async ({ data }: any) => {
  //       const returnUrl = router.query.returnUrl

  //       if (data.challenge === NEW_PASSWORD_REQUIRED) {
  //         setCredentials({ ...credentials, otp })
  //         const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/change-password'

  //         return router.replace(redirectURL as string)
  //       } else {
  //         const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
  //         setUser({
  //           email: credentials.email,
  //           role: 'admin',
  //           token: data.token,
  //           id: data.id
  //         })
  //         window.localStorage.setItem(
  //           'userData',
  //           JSON.stringify({
  //             email: credentials.email,
  //             session: data.token,
  //             role: 'admin'
  //           })
  //         )
  //         window.localStorage.setItem(
  //           'deviceData',
  //           JSON.stringify({
  //             deviceId: credentials.deviceId,
  //             deviceName: credentials.deviceName
  //           })
  //         )

  //         return router.replace(redirectURL as string)
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       if (errorCallback) errorCallback(err)
  //     })
  // }


  const handleOTP = ({ otp }: OTPParams, errorCallback?: ErrCallbackType) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/login', {
        ...credentials,
        otp
      })
      .then(async ({ data }: any) => {
        const returnUrl = router.query.returnUrl

        // if (data.challenge === NEW_PASSWORD_REQUIRED) {
        //   setCredentials({ ...credentials, otp })
        //   const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/change-password'

        //   return router.replace(redirectURL as string)
        // } else {
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
        setUser({
          email: credentials.email,
          role: 'admin',
          token: data.token,
          id: data.id
        })
        window.localStorage.setItem(
          'userData',
          JSON.stringify({
            email: credentials.email,
            session: data.token,
            role: 'admin'
          })
        )

        return router.replace(redirectURL as string)

        // }
      })
      .catch(err => {
        console.log(err)
        if (errorCallback) errorCallback(err)
      })
  }


  // configuration for Resending OTP
  const handleResendOTP = (successCallback?: SuccessCallbackType, errorCallback?: ErrCallbackType) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/resend-otp', {
        variables: { ...credentials }
      })
      .then(({ data }: any) => {
        if (data.errors) {
          if (data.errors.length !== 0) {
            errorCallback && errorCallback(data.error[0])
          }
        } else {
          successCallback && successCallback(true)
        }
      })
      .catch(err => {
        console.log(err)
        if (errorCallback) errorCallback(err)
      })
  }

  const handleChangePassword = ({ newPassword }: { newPassword: string }, errorCallback?: ErrCallbackType) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/login', {
        ...credentials,
        newPassword
      })
      .then(async ({ data }: any) => {
        const returnUrl = router.query.returnUrl
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
        setUser({
          email: credentials.email,
          role: 'admin',
          token: data.token,
          id: data.id
        })
        window.localStorage.setItem(
          'userData',
          JSON.stringify({
            email: credentials.email,
            session: data.token,
            role: 'admin'
          })
        )
        window.localStorage.setItem(
          'deviceData',
          JSON.stringify({
            deviceId: credentials.deviceId,
            deviceName: credentials.deviceName
          })
        )

        return router.replace(redirectURL as string)
      })
      .catch(error => {
        console.log(error)
      })

      .catch(err => {
        console.log(err)
        if (errorCallback) errorCallback(err)
      })
  }

  // configuration of change password
  const handleForgotPassword = (
    params: { email?: string },
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/forgot-password', {
        email: params.email
      })
      .then(({ data }: any) => {
        if (data.errors) {
          if (data.errors.length !== 0) {
            errorCallback && errorCallback(data.error[0])
          }
        } else {
          successCallback && successCallback(true)
        }
      })
      .catch(err => {
        console.log(err)
        if (errorCallback) errorCallback(err)
      })
  }

  const handleChangeCurrentPassword = (
    params: { newPassword?: string; token?: string | string[] | undefined },
    successCallback?: SuccessCallbackType,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(process.env.NEXT_PUBLIC_KEY + '/auth/forgot-password' + `/${params.token}`, {
        newPassword: params.newPassword
      })
      .then(({ data }: any) => {
        if (data.errors) {
          if (data.errors.length !== 0) {
            errorCallback && errorCallback(data.error[0])
          }
        } else {
          successCallback && successCallback(true)
        }
      })
      .catch(err => {
        console.log(err)
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    router.push('/login')
  }

  const values = {
    credentials,
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    OTP: handleOTP,
    resendOtp: handleResendOTP,
    changePassword: handleChangePassword,
    forgotPassword: handleForgotPassword,
    changeCurrentPassword: handleChangeCurrentPassword,

    // principalOnboarding: handleOnboarding,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
